export const PARTIAL_SEARCH_PARAM = "fresh-partial";
export const PARTIAL_ATTR = "f-partial";
export const LOADING_ATTR = "f-loading";
export const CLIENT_NAV_ATTR = "f-client-nav";
export const DATA_KEY_ATTR = "data-fresh-key";
export const DATA_CURRENT = "data-current";
export const DATA_ANCESTOR = "data-ancestor";

export const enum PartialMode {
  REPLACE,
  APPEND,
  PREPEND,
}

// denoCacheMetadata={"headers":{"strict-transport-security":"max-age=63072000; includeSubDomains; preload","date":"Fri, 17 Nov 2023 12:47:43 GMT","server-timing":"fetchSource;dur=10","x-amz-cf-id":"42iEhi1WskTk454M3VWGS3sa1f329i7FfzZ9CQuUcV_RvpuiCyYKkw==","x-amz-version-id":"6iySwPPYBwBqVmlddOIE3RtbNEZz8WQH","x-content-type-options":"nosniff","x-cache":"Hit from cloudfront","vary":"Accept-Encoding, Origin","cross-origin-opener-policy":"same-origin","x-amz-replication-status":"COMPLETED","cross-origin-resource-policy":"same-origin","content-length":"386","x-frame-options":"DENY","age":"31442491","cache-control":"public, max-age=31536000, immutable","last-modified":"Tue, 07 Nov 2023 18:33:27 GMT","x-amz-cf-pop":"IAD61-P1","access-control-allow-origin":"*","cross-origin-embedder-policy":"same-origin","referrer-policy":"strict-origin-when-cross-origin","x-amz-server-side-encryption":"AES256","via":"http/2 edgeproxy-h","etag":"\"cbf7306b660163352728761242054293\"","content-type":"application/typescript; charset=utf-8","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","accept-ranges":"bytes","server":"deno/gcp-us-east4"},"url":"https://deno.land/x/fresh@1.5.4/src/constants.ts","time":1731667753}